<template>
  <div>
    <div class="row">
      <div class="col-12 mb-1" align="right">
        <button
          v-if="$store.state.user.perfil_id == 2"
          class="btn btn-success"
          data-toggle="modal"
          data-target="#modalImport"
        >
          Importar
        </button>
        <div class="ibox-title" style="padding: 16px">
          <div
            class="modal fade"
            id="modalImport"
            tabindex="-1"
            role="dialog"
            aria-labelledby="modalImportTitle"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLongTitle">
                    Importar
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <div class="alert alert-warning text-center" role="alert">
                    Para importar uma Unidade de Saúde, insira o CNES ou
                    Pesquise por uma Unidade de Saúde.
                    <button
                      type="button"
                      class="close"
                      data-dismiss="alert"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="row">
                    <div class="col-md-12 mb-1">
                      <label for="cnes">CNES</label>
                      <input
                        v-model="cnes"
                        id="cnes"
                        type="text"
                        class="form-control"
                        @input="onCnesChange"
                      />
                    </div>
                    <div class="col-md-12 mb-1" v-show="selectedUnidade">
                      <label for="cnes">Nome da Unidade</label>
                      <input
                        v-model="nome_razao_social"
                        id="nome_razao_social"
                        type="text"
                        class="form-control"
                        @input="onCnesChange"
                      />
                    </div>
                    <div class="col-md-6 mb-1" v-show="selectedUnidade">
                      <label for="cnes">Endereço</label>
                      <input
                        v-model="endereco_estabelecimento"
                        id="endereco_estabelecimento"
                        type="text"
                        class="form-control"
                        @input="onCnesChange"
                      />
                    </div>
                    <div class="col-md-6 mb-1" v-show="selectedUnidade">
                      <label for="cnes">Bairro</label>
                      <input
                        v-model="bairro_estabelecimento"
                        id="bairro_estabelecimento"
                        type="text"
                        class="form-control"
                        @input="onCnesChange"
                      />
                    </div>
                    <div class="col-md-4 mt-1" v-show="selectedUnidade">
                      <label><span class="required">*</span> Estado</label>
                      <v-select
                        :options="ufs"
                        :reduce="(uf) => uf.uf_id"
                        :label="'uf_nome'"
                        v-on:input="getEstadoMunicipioDestino"
                        v-model="uf_id"
                        class="vselect"
                      />
                    </div>
                    <div class="col-md-4 mt-1" v-show="selectedUnidade">
                      <label><span class="required">*</span> Município</label>
                      <v-select
                        :options="municipios"
                        :reduce="(municipio) => municipio.municipio_id"
                        :label="'municipio_nome'"
                        v-model="municipio_id"
                        class="vselect"
                      />
                    </div>
                    <div class="col-md-4 mt-1" v-show="selectedUnidade">
                      <label for="cnes">Número</label>
                      <input
                        v-model="numero_estabelecimento"
                        id="numero_estabelecimento"
                        type="text"
                        class="form-control"
                        @input="onCnesChange"
                      />
                    </div>

                    <div class="col-md-12 mb-2 mt-2">
                      <label for="unidadeSaude">Unidades de Saúde</label>
                      <v-select
                        id="unidadeSaude"
                        class="form-select"
                        v-model="selectedUnidade"
                        @search="searchUnidade"
                        label="nome_razao_social"
                        :options="unidadesSaude"
                        :filterable="false"
                        v-on:input="selectUnidadeDestino"
                      >
                        <template #no-options="{ search, searching, loading }">
                          Digite para pesquisar Unidades de Saúde
                        </template>

                        <template #option="{ nome_razao_social }">
                          <div class="d-center">{{ nome_razao_social }}</div>
                        </template>

                        <template #selected-option="{ nome_razao_social }">
                          <div class="selected d-center">
                            {{ nome_razao_social }}
                          </div>
                        </template>
                      </v-select>
                    </div>
                    <div class="col-md-12 mb-1" v-show="selectedUnidade">
                      <label for="Name">
                        <span class="required">*</span> Tipo de Unidades de
                        Saude
                      </label>
                      <select
                        class="form-control"
                        v-model="tipo_unidade_saude_id"
                      >
                        <option
                          v-for="tipo in tipos_unidade_saude"
                          :key="tipo.tipo_unidade_saude_id"
                          :value="tipo.tipo_unidade_saude_id"
                        >
                          {{ tipo.tipo_unidade_saude_nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    @click="importarUnidade"
                  >
                    Importar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <base-crud
          :ref="'usersCrud'"
          crudName="Unidades de Saúde"
          newText="Novo"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="false"
          :enableReset="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "unidadeSaudeTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      cnes: "",
      nome_razao_social: "",
      endereco_estabelecimento: "",
      bairro_estabelecimento: "",
      uf_id: "",
      municipio_id: "",
      tipo_unidade_saude_id: "",
      numero_estabelecimento: "",
      ufs: [],
      municipios: [],
      selectedUnidade: null,
      tipos_unidade_saude: [],
      unidadesSaude: [],
      columns: [
        "unidade_saude_id",
        "unidade_saude_nome",
        "tipo_unidade_saude_id",
        "data_registro_f",
        "actions",
      ],
      tableData: [
        "id",
        "unidade_saude_id",
        "unidade_saude_nome",
        "tipo_unidade_saude_id",
        "endereco",
        "bairro",
        "complemento",
        "cep",
        "ponto_referencia",
        "telefone",
        "cnes",
        "situacao_unidade",
        "data_registro_f",
        "numero",
        "municipio_id",
        "tipo_unidade_saude_id",
      ],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: [
          "unidade_saude_id",
          "unidade_saude_nome",
          "tipo_unidade_saude_id",
        ],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          unidade_saude_id: "ID",
          unidade_saude_nome: "Nome da Unidade",
          municipio_id: "Municipio",
          tipo_unidade_saude_id: "Tipo da Unidade",
          data_registro_f: "Data criação",
          actions: "Ações",
        },
        sortable: ["unidade_saude_nome"],
        sortIcon: {
          base: "fas",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {
          tipo_unidade_saude_id: function (h, row, index) {
            return row.tipo_unidade_saude_id
              ? row.tipo_unidade_saude.tipo_unidade_saude_nome
              : "Sem Nome";
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          console.log(this.$parent.$parent);

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = ["tipo_unidade_saude"];

          return axios.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "unidade_saude",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  watch: {
    selectedUnidade(newVal) {
      if (newVal && newVal.codigo_cnes) {
        this.cnes = newVal.codigo_cnes;
        this.nome_razao_social = newVal.nome_razao_social;
        this.endereco_estabelecimento = newVal.endereco_estabelecimento;
        this.bairro_estabelecimento = newVal.bairro_estabelecimento;
        this.codigo_uf = newVal.codigo_uf;
        this.codigo_municipio = newVal.codigo_municipio;
        this.numero_estabelecimento = newVal.numero_estabelecimento;
      } else {
        this.cnes = "";
        this.nome_razao_social = "";
        this.endereco_estabelecimento = "";
        this.bairro_estabelecimento = "";
        this.codigo_uf = "";
        this.codigo_municipio = "";
        this.numero_estabelecimento = "";
      }
    },
  },
  methods: {
    getUFs: function () {
      const self = this;
      let api =
        self.$store.state.api + "ufs?orderByAsc=uf_nome&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.ufs = response.data.data;
          self.$loading(false);
          self.isLoading = false;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
          self.isLoading = false;
        });
    },
    selectUnidadeDestino() {
      const self = this;

      const estabelecimento = self.selectedUnidade;
      self.nome_razao_social = estabelecimento.nome_razao_social;
      self.endereco_estabelecimento = estabelecimento.endereco_estabelecimento;
      self.numero_estabelecimento = estabelecimento.numero_estabelecimento;
      self.bairro_estabelecimento = estabelecimento.bairro_estabelecimento;

      self.codigo_municipio = estabelecimento.codigo_municipio;
      self.codigo_uf = estabelecimento.codigo_uf;

      self.mapearCodigosParaIds();
    },

    mapearCodigosParaIds() {
      const self = this;

      self.$loading(true);

      if (!self.codigo_municipio || !self.codigo_uf) {
        return;
      }

      let apiMunicipio = `${self.$store.state.api}municipios?codigo_municipio=${self.codigo_municipio}&paginated=false`;

      axios
        .get(apiMunicipio)
        .then((response) => {
          if (response.data.data && Array.isArray(response.data.data)) {
            const municipioData = response.data.data.find(
              (municipio) =>
                String(municipio.municipio_codigo) ===
                String(self.codigo_municipio)
            );
            if (municipioData) {
              self.municipio_id = municipioData.municipio_id;
              self.municipio_nome = municipioData.municipio_nome;

              self.municipios = [municipioData];
            }
          }
        })
        .catch((error) => {
          console.error("Erro ao buscar município:", error.response.data);
        });

      let apiUf = `${self.$store.state.api}ufs?codigo_uf=${self.codigo_uf}&paginated=false`;

      axios
        .get(apiUf)
        .then((response) => {
          const ufData = response.data.data.find(
            (uf) => String(uf.uf_codigo) === String(self.codigo_uf)
          );
          if (ufData) {
            self.uf_id = ufData.uf_id;
            self.uf_nome = ufData.uf_nome;

            self.ufs = [ufData];
          }
          self.$loading(false);
        })
        .catch((error) => {
          console.error("Erro ao buscar UF:", error.response.data);
        });
    },
    clearSelectedUnidade() {
      this.selectedUnidade = "";
    },
    getTipoUnidadeSaude: function () {
      const self = this;

      let api =
        self.$store.state.api + "tipo_unidade_saude?paginated=false&status=1";

      axios
        .get(api)
        .then((response) => {
          self.tipos_unidade_saude = response.data.data;
          console.log(response.data.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    onCnesChange() {
      if (this.cnes.length > 0) {
        this.searchUnidade(this.cnes, (loading) => {});
      }
    },
    searchUnidade(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce((loading, search, vm) => {
      let api = vm.$store.state.api + "unidade_saude_import?limit=15";

      // Verificar se há um CNES fornecido
      if (vm.cnes) {
        api += "&cnes=" + vm.cnes;
      } else if (search) {
        // Converter o valor de busca para minúsculas antes de enviar a requisição
        api += "&nome_razao_social=" + search.toLowerCase();
      }

      axios
        .get(api)
        .then((response) => {
          const unidades = response.data.data;

          if (vm.cnes && unidades.length === 0) {
            vm.$message(
              null,
              "Unidade de Saúde não encontrada com o CNES: " + vm.cnes,
              "error"
            );
            vm.selectedUnidade = null;
            return;
          }

          if (vm.cnes && unidades.length === 1) {
            vm.selectedUnidade = unidades[0];
          } else {
            vm.unidadesSaude = unidades;
          }
        })
        .then(() => {
          loading(false);
        })
        .catch((error) => {
          console.log("Erro:", error);
          vm.$toast.error(error.response.data.message);
        });
    }, 700),

    importarUnidade: function () {
      const self = this;

      if (!self.cnes) {
        self.$message(null, "CNES é obrigatório", "error");
        return;
      }

      if (self.selectedUnidade && !self.tipo_unidade_saude_id) {
        self.$message(null, "Tipo de unidade de saúde é obrigatório", "error");
        return;
      }

      let api = self.$store.state.api + "unidade-saude/importar";

      self.$loading(true);

      axios
        .post(api, {
          cnes: self.cnes,
          tipo_unidade_saude_id: self.tipo_unidade_saude_id,
          nome_razao_social: self.nome_razao_social,
          endereco_estabelecimento: self.endereco_estabelecimento,
          bairro_estabelecimento: self.bairro_estabelecimento,
          codigo_uf: self.codigo_uf,
          codigo_municipio: self.codigo_municipio,
          numero_estabelecimento: self.numero_estabelecimento,
        })
        .then((response) => {
          if (response.data.success) {
            self.$message(null, response.data.message, "success");
            self.cnes = "";
            self.un;
            self.tipo_unidade_saude_id = "";
            self.selectedUnidade = "";
            self.$loading(false);
          } else {
            self.$message(null, response.data.message, "error");
            self.$loading(false);
          }
        })
        .catch((error) => {
          self.$message(
            null,
            error.response ? error.response.data : "Erro desconhecido",
            "error"
          );
          self.$loading(false);
        });
    },
    makeFormData: function () {
      const self = this;
      let fd = new FormData();

      let fileImport = document.getElementById("fileImport");

      fd.append("fileImport", fileImport.files[0] ? fileImport.files[0] : "");

      return fd;
    },
    query: function (query) {
      let columns = {
        unidade_saude_id: "unidade_saude_id",
        unidade_saude_nome: "unidade_saude_nome",
        tipo_unidade_saude_id: "tipo_unidade_saude[tipo_unidade_saude_nome]",
        status: "status",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
  mounted: function () {
    const self = this;
    self.getTipoUnidadeSaude();
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>
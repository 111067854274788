<template>
  <div>
    <div class="row mt-2">
      <div class="col-md-2">
        <label>Número da Solicitação</label>
        <input
          type="text"
          class="form-control"
          v-model="filters.numero_solicitacao"
        />
      </div>
      <div class="col-md-2">
        <label>Data de Vinculo</label>
        <input type="date" class="form-control" v-model="filters.data_rih" />
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <button class="btn btn-primary" @click="getSolitacoes">Filtrar</button>
      </div>
      <div class="col-md-2">
        <button class="btn btn-success" @click="limparFiltros">
          Limpar filtros
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-2">
        <button
          class="btn btn-export-j4 float-left btn-sm"
          data-toggle="modal"
          data-target="#modalExport"
        >
          Exportar em Excel
        </button>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalExport"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalExportTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Exportar</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-content modal-lg mt-1">
            <div
              class="alert alert-warning"
              role="alert"
              v-if="!filters.data_inicio && !filters.data_fim"
            >
              Para exportar todos os registros, basta não definir nenhum
              intervalo de datas e proceder com a exportação.
              <button
                type="button"
                class="close"
                data-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>

            <div class="d-flex justify-content-between">
              <div class="col-md-6 mb-1">
                <label>Data de Ínicio</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="filters.data_inicio"
                />
              </div>
              <div class="col-md-6 mb-1">
                <label>Data do Fim</label>
                <input
                  type="date"
                  class="form-control"
                  v-model="filters.data_fim"
                />
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Fechar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="exportToExcel"
            >
              Exportar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card content-crud">
          <div class="ibox">
            <div class="ibox-title" style="padding: 16px">
              <h3>Solicitações</h3>
            </div>
            <div class="ibox-content">
              <div class="table-responsive" style="padding: 10px">
                <table
                  class="VueTables__table table table-striped table-bordered table-hover"
                  style="padding: 10px"
                >
                  <thead>
                    <tr>
                      <th scope="col">NÚMERO SOLICITACAO</th>
                      <th scope="col">NOME DO DOCUMENTO</th>
                      <th scope="col">DATA DA SOLICITAÇÃO</th>
                      <th scope="col">DATA DO TRANSPORTE</th>
                      <th scope="col">DATA DO RIH VINCULADO</th>
                      <th scope="col">LOGIN</th>
                      <th scope="col">AÇÕES</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="solicitacao in solicitacoes"
                      :key="solicitacao.numero_solicitacao"
                    >
                      <td>{{ solicitacao.numero_solicitacao }}</td>
                      <td>{{ solicitacao.ras }}</td>
                      <td>{{ solicitacao.data_solicitacao_f }}</td>
                      <td>{{ solicitacao.data_procedimento_f }}</td>
                      <td>
                        {{ solicitacao.data_rih ? solicitacao.data_rih_f : "" }}
                      </td>
                      <td>
                        {{
                          solicitacao.usuario_rih_id
                            ? solicitacao.usuario_rih.login
                            : ""
                        }}
                      </td>
                      <td>
                        <router-link
                          :to="'/programacoes/programacao/' + solicitacao.id"
                          title="Visualizar"
                          class="btn btn-crud view"
                          target="_blank"
                        >
                          <i class="fas fa-eye"></i>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="col-md-6 mt-1 mx-auto d-flex justify-content-center align-items-center"
                >
                  <pagination
                    :records="total"
                    :options="options"
                    :per-page="15"
                    @paginate="getSolitacoes"
                  ></pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import { Pagination } from "vue-pagination-2";

export default {
  data: function () {
    return {
      options: {
        texts: {
          count: "Mostrando {from} de {to} até {count} de registros",
        },
        chunk: 4,
        chunksNavigation: "fixed",
      },
      total: 0,
      page: 1,
      autoRefreshAtivado: false,
      pages: [],
      solicitacoes: [],
      showFinished: false,
      filters: {
        numero_solicitacao: "",
        data_rih: "",
      },
    };
  },
  components: {
    BaseCrud,
    vSelect,
    Pagination,
  },

  methods: {
    exportToExcel() {
      const self = this;
      const dataInicio = self.filters.data_inicio;
      const dataFim = self.filters.data_fim;

      if (dataInicio || dataFim) {
        if (dataInicio && dataFim) {
          var url = `${self.$store.state.url}rih/exportar?data_inicio=${dataInicio}&data_fim=${dataFim} 23:59:59`;
          window.open(url, "_blank").focus();
        } else {
          self.$message(
            null,
            "Por favor, preencha ambos os campos de data.",
            "error"
          );
          return;
        }
      } else {
        var url = `${self.$store.state.url}rihs/exportar`;
        window.open(url, "_blank").focus();
      }
    },
    getSolitacoes(page) {
      const self = this;

      self.page = page || 1;

      let api = self.$store.state.api + "solicitacoes/rihs?page=" + self.page;

      self.$loading(true);
      axios
        .post(api, self.filters)
        .then((response) => {
          self.solicitacoes = response.data.data;
          self.total = response.data.total;
          self.$loading(false);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
          self.$loading(false);
        });
    },

    limparFiltros() {
      for (let key in this.filters) {
        this.filters[key] = "";
      }
      this.getSolitacoes();
    },
  },
  mounted() {
    const savedFilters = JSON.parse(localStorage.getItem("savedFilters"));
    if (savedFilters) {
      this.filters = savedFilters;
    }

    this.getSolitacoes();
  },
  watch: {
    filters: {
      handler(newFilters) {
        localStorage.setItem("savedFilters", JSON.stringify(newFilters));
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}

.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>